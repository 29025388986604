import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { RickRoll } from '../components';

const Meet: React.FC = () => {
  return (
    <RickRoll />
  );
}

export default Meet;
